import React from "react";
import styled from "styled-components";

// assets
import Gif from "../../assets/avatar-the-last-airbender-aang.gif";

function Footer() {
  return (
    <Head>
      <Container>
        <Img src={Gif} />
      </Container>
    </Head>
  );
}

export default Footer;

// Styles

const Head = styled.div`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  max-width: 20%;
`;

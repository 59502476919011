import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";

function Nav({ color, setColor }) {
  const [pickerVisible, setPickerVisible] = useState(false);

  const handleChange = (newColor) => {
    setColor(newColor.hex);
  };

  const togglePicker = (event) => {
    event.stopPropagation(); // Prevents triggering the window click event
    setPickerVisible(!pickerVisible);
  };

  const handleWindowClick = () => {
    setPickerVisible(false);
  };

  useEffect(() => {
    if (pickerVisible) {
      window.addEventListener("click", handleWindowClick);
    } else {
      window.removeEventListener("click", handleWindowClick);
    }

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [pickerVisible]);

  return (
    <Head>
      <Container color={color}>
        <div>
          <a
            href="https://github.com/DecentralizedDave"
            target="_blank"
            rel="noreferrer"
            style={{ color }}
          >
            README.md
          </a>
          <a
            href="https://twitter.com/petedavidson"
            target="_blank"
            rel="noreferrer"
            style={{ color }}
          >
            Twitter
          </a>
        </div>
        <ColorPickerWrapper>
          <ColorButton onClick={togglePicker} color={color} />
          {pickerVisible && (
            <PickerContainer onClick={(e) => e.stopPropagation()}>
              <ChromePicker color={color} onChange={handleChange} />
            </PickerContainer>
          )}
        </ColorPickerWrapper>
      </Container>
    </Head>
  );
}

export default Nav;

const Head = styled.div`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  padding-top: 40px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 526px) {
    padding: 0px 15px 0px 15px;
  }
  & a {
    margin-right: 10px;
    font-size: 18px;
  }
`;

const ColorPickerWrapper = styled.div`
  position: relative;
`;

const ColorButton = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 2px;
  background-color: ${({ color }) => color};
  cursor: pointer;
  outline: none;
`;

const PickerContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 999;
`;

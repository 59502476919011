import React, { useState } from "react";
import styled, { css } from "styled-components";

// assets
import DownArrow from "../../assets/downarrow.svg";

function Projects({ borderColor }) {
  const [number, setNumber] = useState(-1);

  class FAQ {
    constructor(question, answer, roles, link) {
      this.question = question;
      this.answer = answer;
      this.roles = roles;
      this.link = link;
    }
  }

  const questions = [
    new FAQ(
      "Dogelon Frax Eth Staking",
      "An Eth Staking platform powered by Frax.",
      "UX/UI Design, Front-end Development",
      "https://frax.dogelonmars.com/"
    ),
    new FAQ(
      "Dogelon Stargate",
      "A platform for transferring $ELON tokens between different blockchain networks (Ethereum/Solana)",
      "UX/UI Design, Front-end Development",
      "https://stargate.dogelonmars.com/"
    ),
    new FAQ(
      "Dogelon Puzzle Vaults",
      "A puzzle vault game where users can solve riddles and win prizes all on chain. (All Vaults solved)",
      "UX/UI Design, Front-end Development",
      "https://shadowgate.dogelonmars.com/"
    ),
    new FAQ(
      "McPepe's AI",
      "An AI platform for generating unique variations of the popular Pepe meme. With capacity for 2 million Pepes per day, the platform quickly gained popularity, minting over 40,000 Pepes directly to an Ethereum collection within hours of launch.",
      "UX/UI Design, Front-end Development",
      "https://mcpepes.com/"
    ),
    new FAQ(
      "Mystic.com",
      "App for burning @LFG BRC-20 tokens in exchange for Mystic Runes",
      "Front-end Development",
      "https://burn.mystic.com"
    ),
    new FAQ(
      "Xenopets",
      "An upcoming unique story-driven Bitcoin Ordinal collection",
      "Founder",
      "https://xenopets.io/"
    ),
    new FAQ("Five Penguins", "3,125 squads minted on Ethereum", "UX/UI Design", "https://fivepenguins.art/"),
  ];

  const Question = ({ question, answer, link, roles, cb, i, j }) => (
    <QuestionDiv
      borderColor={borderColor}
      isOpen={j === i}
      onClick={() => {
        if (j !== i) {
          cb(i);
        } else {
          cb(-1);
        }
      }}
    >
      <TextDiv>
        <StyledText>{question}</StyledText>
        <ImgDiv>
          <Img src={DownArrow} />
        </ImgDiv>
      </TextDiv>
      <Answer isOpen={j === i}>{answer}</Answer>
      <Answer isOpen={j === i}>Roles: {roles}</Answer>
      <Answer isOpen={j === i}>
        <Link href={link} target="_blank" rel="noreferrer">
          {link}
        </Link>
      </Answer>
    </QuestionDiv>
  );

  return (
    <Header>
      <Container>
        {questions.map((item, i) => (
          <Question
            key={i}
            question={item.question}
            answer={item.answer}
            roles={item.roles}
            link={item.link}
            cb={setNumber}
            i={i}
            j={number}
          />
        ))}
      </Container>
    </Header>
  );
}

export default Projects;

const Header = styled.div`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  padding-top: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 526px) {
    padding: 0px 15px 0px 15px;
    }
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Img = styled.img`
  width: 45px;
  height: auto;
  filter: brightness(0) invert(1);
`;

const ImgDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionDiv = styled.div`
  width: 100%;
  max-height: ${({ isOpen }) => (isOpen ? "700px" : "66px")};
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid ${({ borderColor }) => borderColor || "#ffffff"};
  &:hover {
    opacity: 0.8;
  }
`;

const StyledText = styled.h2`
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  margin-left: 20px;
`;

const Link = styled.a`
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 500;
`;

const Answer = styled.p`
  margin-left: 20px;
  margin-right: 20px;
  color: #ffffff;
  font-weight: 500;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
`;

// Packages
import React, { useState } from "react";
import styled from "styled-components"

// Components
import Nav from "./components/Navbar";
import Header from "./components/Header";
import Projects from "./components/ProjectsSection";
import Footer from "./components/Footer";

function App() {
  const [color, setColor] = useState("#ffffff");

  return (
    <Container>
      <Nav color={color} setColor={setColor} />
      <Header/>
      <Projects borderColor={color} />
      <Footer/>
    </Container>
  );
}

export default App;

// styles

const Container = styled.div`
  
`
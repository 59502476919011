// Packages
import React from "react";
import styled from "styled-components";

// Assets
import HeaderImg from "../../assets/testheader.png"

function Header() {
    return (
        <Head>
            <Container>
                <Img src={HeaderImg}/>
            </Container>
        </Head>
    )
}

export default Header

// Styles

const Head = styled.div`
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    padding-top: 40px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 526px) {
        padding: 0px 15px 0px 15px;
        }
`

const Img = styled.img`
    max-width: 100%;
`
